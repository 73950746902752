/* eslint-disable global-require */
import React, { ReactElement, memo } from 'react'
import { clientInfo } from 'configureBrand'
import { isNotIE } from 'helpers'
import { Helmet } from 'react-helmet-async'

const SmartBanner = memo((): ReactElement => {
  let enabledPlatform = 'none'
  const isAndroid = /Android/i.test(window.navigator.userAgent)
  if (isNotIE() && clientInfo.androidApp.id && isAndroid) {
    enabledPlatform = 'android'
    require('../../../../node_modules/smartbanner.js/dist/smartbanner.min')
    require('../../../../node_modules/smartbanner.js/dist/smartbanner.min.css')
  }

  return (
    <Helmet>
      <meta name="apple-itunes-app" content={`app-id=${clientInfo.iOSAppId}`} />
      <meta name="smartbanner:title" content={clientInfo.androidApp.title} />
      <meta name="smartbanner:author" content={clientInfo.androidApp.author} />
      <meta name="smartbanner:price" content="FREE" />
      <meta
        name="smartbanner:price-suffix-google"
        content=" - In Google Play"
      />
      <meta
        name="smartbanner:icon-google"
        content={clientInfo.androidApp.image}
      />
      <meta name="smartbanner:button" content="VIEW" />
      <meta
        name="smartbanner:button-url-google"
        content={`https://play.google.com/store/apps/details?id=${clientInfo.androidApp.id}`}
      />
      <meta name="smartbanner:enabled-platforms" content={enabledPlatform} />
      <meta name="smartbanner:custom-design-modifier" content="ios" />
      <meta name="smartbanner:close-label" content="Close" />
    </Helmet>
  )
})

export default SmartBanner
